import controller from './active-alerts.controller';

const template = require('./active-alerts.html');

export default {
    selector: 'activeAlerts',
    options: {
        bindings: {
            alerts: '=',
        },
        // bindings: {
        //     rule: '<',
        //     isNew: '<',
        //     onUpdate: '&',
        //     onDelete: '&',
        //     onCreate: '&'
        // },
        controller,
        template
    }
};
