
import { getTemperatureUnitSuffix, celsiusToFahrenheit } from 'services/utils';
import { RANGE_LABEL_WEEK, SENSOR_GRAPH_RANGES, ALERT_EVENT_TYPES } from 'services/charting/constants';
import { fields } from '../rules/rules-detail/rules-detail.controller';
import { Duration } from '../rules/rules-detail/duration/duration.controller';
/* @ngInject */
export default class ActiveAlertsController {
    
    constructor($scope, AlertsAndRulesService, SensorService, FeatureFlags, UserPreferencesManager) {
        this.$scope = $scope;
        this.AlertsAndRulesService = AlertsAndRulesService;
        this.SensorService = SensorService;
        this.FeatureFlags = FeatureFlags;
        this.UserPreferencesManager = UserPreferencesManager;
    }
    
    $onInit() {
        this.fields = fields;
        this.ranges = SENSOR_GRAPH_RANGES
        this.loadedAlerts = false

        this.AlertsAndRulesService.listActiveAlerts().then(response => {
            const alerts = response.data;
            alerts.forEach(alert => {
                alert.showRangeDropdown = false;
                alert.currentRange = RANGE_LABEL_WEEK;
                alert.triggered = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED).triggered;
                alert.triggerDescription = this.triggerDescription(alert);
                
                alert.events.sort((a, b) => {
                    return new Date(b.createTime) - new Date(a.createTime);
                });
            });

            const deviceIds = alerts.map(alert => alert.device.split('/')[3]);

            this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
                alerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.device = device;
                })
                // Sort alerts by resolveTime or archivedTime
                alerts.sort((a, b) => {
                    return new Date(b.resolveTime || b.archivedTime) - new Date(a.resolveTime || a.archivedTime);
                });
                this.alerts = alerts
                this.loadedAlerts = true                
                this.$scope.$applyAsync()
            })  
        })
    }

    triggerDescription(alert) {
        // Returns a clear and explicit trigger description
        // E.g. 'Humidity above 15%' or 'No water present for 5 minutes'
        const trigger = alert.triggered.trigger;
        const measurement = this.fields[trigger.field].displayName;

        const unit = trigger.field === "temperature" ? getTemperatureUnitSuffix() : this.fields[trigger.field].unit

        const humanizedDelay = Duration.humanizedFromString(alert.triggered.triggerDelay);
        const delayString = humanizedDelay ? ` for ${humanizedDelay}` : '';

        // If the upper/lower range exists, the field is temperature, and the user wants fahrenheit,
        // convert the value to fahrenheit. Otherwise leave it as-is.
        let lowerRange = trigger.range?.lower;
        let upperRange = trigger.range?.upper;
        if (lowerRange !== null && trigger.field === "temperature" && this.UserPreferencesManager.useFahrenheit) {
            lowerRange = celsiusToFahrenheit(lowerRange).toFixed(1);
        }
        if (upperRange !== null && trigger.field === "temperature" && this.UserPreferencesManager.useFahrenheit) {
            upperRange = celsiusToFahrenheit(upperRange).toFixed(1);
        }
        
        switch (trigger.field) {
            case 'touch':
                return `${this.fields[trigger.field].displayName}${delayString}`;
            case 'temperature':
            case 'co2':
            case 'relativeHumidity':
                switch (trigger.range.type) {
                    case 'OUTSIDE':
                        if (trigger.range.lower !== null && trigger.range.upper !== null) {
                            return `${measurement} outside range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower !== null && trigger.range.upper === null) {
                            return `${measurement} below ${lowerRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower === null && trigger.range.upper !== null) {
                            return `${measurement} above ${upperRange}${unit}${delayString}`;
                        }
                        return 'No trigger';
                    case 'WITHIN':
                        if (trigger.range.lower !== null && trigger.range.upper !== null) {
                            return `${measurement} within range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower !== null && trigger.range.upper === null) {
                            return `${measurement} above ${lowerRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower === null && trigger.range.upper !== null) {
                            return `${measurement} below ${upperRange}${unit}${delayString}`;
                        }
                        return 'No trigger';
                    default: 
                        return 'No trigger';
                }
            case 'contact':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.contact]}${delayString}`;
            case 'objectPresent':
            case 'waterPresent':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.presence]}${delayString}`;
            case 'motion':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.motion]}${delayString}`;
            case 'touchCount':
                return `Touch count reaches ${trigger.triggerCount}`
            case 'proximityCount':
                return `Proximity count reaches ${trigger.triggerCount}`
            case 'connectionStatus':
                switch (trigger.connection) {
                    case 'CLOUD_CONNECTOR_OFFLINE':
                        return 'Cloud Connector offline';
                    case 'SENSOR_OFFLINE':
                        return 'Sensor offline';
                    default:
                        return 'No trigger';
                }
            default:
                return measurement;
        }
    }
 
    $onDestroy() {}

}
