
import moment from 'moment';
import { getTemperatureUnitSuffix, celsiusToFahrenheit, getHoursMinutesFormat } from 'services/utils';
import { RANGE_LABEL_WEEK, SENSOR_GRAPH_RANGES, ALERT_EVENT_TYPES } from 'services/charting/constants';
import { fields } from '../rules/rules-detail/rules-detail.controller';
import { Duration } from '../rules/rules-detail/duration/duration.controller';
import { States } from '../../../app.router';

import TimelineController from './timeline/timeline.controller';
import TimelineTemplate from './timeline/timeline.html';
/* @ngInject */
export default class ActiveAlertsController {
    
    constructor($scope, $state, AlertsAndRulesService, SensorService, FeatureFlags, UserPreferencesManager, DialogService) {
        this.$scope = $scope;
        this.$state = $state;
        this.AlertsAndRulesService = AlertsAndRulesService;
        this.SensorService = SensorService;
        this.FeatureFlags = FeatureFlags;
        this.UserPreferencesManager = UserPreferencesManager;
        this.DialogService = DialogService;
    }
    
    $onInit() {
        this.fields = fields;
        this.ranges = SENSOR_GRAPH_RANGES
        this.loadedAlerts = false
        this.alerts = []
        
        // Create an array of the last 90 days
        this.days = Array.from({ length: 90 }, (_, i) => {
            return {
                alertCount: 0,
                alerts: [],
                date: moment().subtract(i, 'days'),
                dateFormatted: moment().subtract(i, 'days').format('MMM D, YYYY')
            }
        })
        this.days.reverse() // Reverse the array so the most recent day is first
                
        this.AlertsAndRulesService.listAlertLog().then(response => {
            const alerts = response.data;
            
            alerts.forEach(alert => {                
                alert.triggered = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED).triggered;
                alert.triggerDescription = this.triggerDescription(alert);
                alert.currentRange = RANGE_LABEL_WEEK;
                alert.showOptionsDropdown = false;
                alert.createTimeFormatted = moment(alert.createTime).format(getHoursMinutesFormat());

                // If there alert was archived, get the archived event
                if (alert.status === 'ARCHIVED') {
                    alert.archivedTime = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ARCHIVED).createTime;
                }
            })

            const deviceIds = alerts.map(alert => alert.device.split('/')[3]);

            this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
                alerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.deviceId = alert.device.split('/')[3];
                    alert.device = device;
                    
                    // Check the createTime and see if if matches one
                    // of the days in the last 90 days array
                    const alertDate = moment(alert.createTime).format('YYYY-MM-DD');
                    const day = this.days.find(d => d.date.format('YYYY-MM-DD') === alertDate);
                    if (day) {
                        day.alerts.unshift(alert); // Unshift to add the alert to the beginning of the array (start of day shown first)
                    }
                })
                // Sort alerts by resolveTime or archivedTime
                alerts.sort((a, b) => {
                    return new Date(b.resolveTime || b.archivedTime) - new Date(a.resolveTime || a.archivedTime);
                });
                this.alerts = alerts
                this.loadedAlerts = true                
                this.$scope.$applyAsync()
            })            
        })
    }

    getTriggerTime(alert, format = '') {
        // Get the time the alert was triggered
        const triggeredEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED);
        if (format === 'dateOnly') {
            return triggeredEvent ? moment(triggeredEvent.createTime).format('MMM D') : 'N/A';
        } else if (format === 'timeOnly') { 
            return triggeredEvent ? moment(triggeredEvent.createTime).format(getHoursMinutesFormat()) : 'N/A';
        }
        return triggeredEvent ? moment(triggeredEvent.createTime).format(`MMM D, ${getHoursMinutesFormat()}`) : 'N/A';
    }

    getResolveTime(alert, format = '') {
        // Get the time the alert was resolved or archived
        const timestamp = moment(alert.resolveTime || alert.archivedTime);
        
        if (format === 'dateOnly') {
            return timestamp.format('MMM D');
        } else if (format === 'timeOnly') { 
            return timestamp.format(getHoursMinutesFormat());
        }

        // Return the time in a human readable format, but keep it relative if it was solved in the past 5 days
        const now = moment();
        if (now.diff(timestamp, 'days') <= 4) {
            // Eg. "Monday at 3:00 PM"
            return timestamp.calendar(null, {
                sameDay: `[Today] ${getHoursMinutesFormat()}`,
                lastDay: `[Yesterday] ${getHoursMinutesFormat()}`,
                lastWeek: `dddd ${getHoursMinutesFormat()}`,
                sameElse: `MMM D ${getHoursMinutesFormat()}`
            });
        }
        return timestamp.format(`MMM D, ${getHoursMinutesFormat()}`);
    }


    getDuration(alert) {
        // Get the duration of the alert
        const triggeredEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED);
        const resolvedEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.RESOLVED);
        if (triggeredEvent && resolvedEvent) {
            const duration = moment(resolvedEvent.createTime).diff(moment(triggeredEvent.createTime));
            // Use moment to get the duration in human readable format
            let humanizedDuration = moment.duration(duration).humanize();
            if (humanizedDuration === 'a few seconds') {
                humanizedDuration = '< 1 minute';
            }
            return humanizedDuration;
        }
        return 'N/A';
    }

    alertHasBeenAcknowledged(alert) {
        return alert.events.some(event => event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED);
    }

    acknowledgedByUser(alert) {
        const acknowledgedEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED);
        return acknowledgedEvent ? acknowledgedEvent.acknowledged.account.displayName : '';
    }

    alertHasCorrectiveAction(alert) {
        return alert.events.some(event => event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION);
    }

    correctiveActionByUser(alert) {
        const correctiveActionEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION);
        return correctiveActionEvent ? correctiveActionEvent.correctiveAction.account.displayName : '';
    }

    alertIcon(alert) {
        let iconName = alert.triggered.trigger.field
        if (iconName === 'relativeHumidity') {
            iconName = 'humidity'
        }
        return iconName
    }

    showTimeline(alert) {
        this.DialogService.show({
            controller: TimelineController,
            template: TimelineTemplate,
            controllerAs: '$ctrl',
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: false,
            locals: {
                alert
            }
        })
    }

    getNumberOfUserInteractions(alert) {
        // Get the number of user interactions (acknowledgements, corrective actions, comments)
        return alert.events.filter(event => {
            return event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED || 
                   event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION || 
                   event.type === ALERT_EVENT_TYPES.COMMENT;
        }).length;
    }

    convertEventsForTimeline(alert) {
        // Convert createTime to human readable format
        alert.events.forEach(event => {
            event.createTimeFormatted = moment(event.createTime).format(`MMM D, ${getHoursMinutesFormat()}`);
            event.timeSince = moment(event.createTime).fromNow();

            // Convert type to a more friendly format
            switch (event.type) {
                case ALERT_EVENT_TYPES.TRIGGERED:
                    event.typeFormatted = 'Open - Active Alert';
                    break;
                case ALERT_EVENT_TYPES.ALERT_DELIVERY_SUCCEEDED:
                    if (event.alertDeliverySucceeded.action.type === 'EMAIL') {
                        event.typeFormatted = `Sent ${event.alertDeliverySucceeded.action.type} to ${event.alertDeliverySucceeded.action.email.recipients[0]}`;
                    } 
                    if (event.alertDeliverySucceeded.action.type === 'SMS') {
                        event.typeFormatted = `Sent ${event.alertDeliverySucceeded.action.type} ${event.alertDeliverySucceeded.action.sms.recipients[0]}`;
                    }
                    break;
                case ALERT_EVENT_TYPES.ALERT_DELIVERY_FAILED:
                    event.typeFormatted = `Failed to deliver ${event.alertDeliveryFailed.action.type}`;
                    break;
                case ALERT_EVENT_TYPES.ACKNOWLEDGED:
                    event.typeFormatted = `Acknowledged by ${event.acknowledged.account.displayName}`;
                    break;
                case ALERT_EVENT_TYPES.CORRECTIVE_ACTION:
                    event.typeFormatted = 'Corrective Action';
                    break;
                case ALERT_EVENT_TYPES.COMMENT:
                    event.typeFormatted = `Comment by ${event.comment.account.displayName}`;
                    break;
                case ALERT_EVENT_TYPES.RESOLVED:
                    event.typeFormatted = 'Resolved';
                    break;
                case ALERT_EVENT_TYPES.ARCHIVED:
                    if (event.archived.description === 'Archived by user') {
                        event.typeFormatted = `Archived by ${event.archived.account.displayName}`;
                    } else {
                        event.typeFormatted = event.archived.description;
                    }
                    break;
                default:
                    event.typeFormatted = event.type;
                    break
            }
        })        

    }

    triggerDescription(alert) {
        // Returns a clear and explicit trigger description
        // E.g. 'Humidity above 15%' or 'No water present for 5 minutes'
        const trigger = alert.triggered.trigger;
        const measurement = this.fields[trigger.field].displayName;

        const unit = trigger.field === "temperature" ? getTemperatureUnitSuffix() : this.fields[trigger.field].unit

        const humanizedDelay = Duration.humanizedFromString(alert.triggered.triggerDelay);
        const delayString = humanizedDelay ? ` for ${humanizedDelay}` : '';

        // If the upper/lower range exists, the field is temperature, and the user wants fahrenheit,
        // convert the value to fahrenheit. Otherwise leave it as-is.
        let lowerRange = trigger.range?.lower;
        let upperRange = trigger.range?.upper;
        if (lowerRange !== null && trigger.field === "temperature" && this.UserPreferencesManager.useFahrenheit) {
            lowerRange = celsiusToFahrenheit(lowerRange).toFixed(1);
        }
        if (upperRange !== null && trigger.field === "temperature" && this.UserPreferencesManager.useFahrenheit) {
            upperRange = celsiusToFahrenheit(upperRange).toFixed(1);
        }
        
        switch (trigger.field) {
            case 'touch':
                return `${this.fields[trigger.field].displayName}${delayString}`;
            case 'temperature':
            case 'co2':
            case 'relativeHumidity':
                switch (trigger.range.type) {
                    case 'OUTSIDE':
                        if (trigger.range.lower !== null && trigger.range.upper !== null) {
                            return `${measurement} outside range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower !== null && trigger.range.upper === null) {
                            return `${measurement} below ${lowerRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower === null && trigger.range.upper !== null) {
                            return `${measurement} above ${upperRange}${unit}${delayString}`;
                        }
                        return 'No trigger';
                    case 'WITHIN':
                        if (trigger.range.lower !== null && trigger.range.upper !== null) {
                            return `${measurement} within range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower !== null && trigger.range.upper === null) {
                            return `${measurement} above ${lowerRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower === null && trigger.range.upper !== null) {
                            return `${measurement} below ${upperRange}${unit}${delayString}`;
                        }
                        return 'No trigger';
                    default: 
                        return 'No trigger';
                }
            case 'contact':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.contact]}${delayString}`;
            case 'objectPresent':
            case 'waterPresent':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.presence]}${delayString}`;
            case 'motion':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.motion]}${delayString}`;
            case 'touchCount':
                return `Touch count reaches ${trigger.triggerCount}`
            case 'proximityCount':
                return `Proximity count reaches ${trigger.triggerCount}`
            case 'connectionStatus':
                switch (trigger.connection) {
                    case 'CLOUD_CONNECTOR_OFFLINE':
                        return 'Cloud Connector offline';
                    case 'SENSOR_OFFLINE':
                        return 'Sensor offline';
                    default:
                        return 'No trigger';
                }
            default:
                return measurement;
        }
    }

    goToDevice(device) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: device.name.split('/')[1],
            sensorId: device.name.split('/')[3]
        });
    }

    goToAlertRule(alert) {
        this.$state.go(States.ALERTS_RULES_DETAIL, {
            ruleId: alert.rule.split('/')[3]
        });
    }
 
    $onDestroy() {}

}
