import { getHighchartTimeFormat} from 'services/utils';

export default {
    chart: {
        animation: false,
        zoomType: 'x',
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: 0,
        marginTop: 20,
        marginBottom: 22,
        marginRight: 0,
        marginLeft: undefined,
        height: 176,
    },
    xAxis: {
        ordinal: false,
        endOnTick: false,
        type: 'datetime',
        tickLength: 8,
        overscroll: 0,
        crosshair: true,
        minRange: 1000 * 60, // 1 minute max zoom
        maxPadding: 0,
        labels: {
            formatter: function() {
                const format = {
                    minute: `%b %e, ${getHighchartTimeFormat()}`, // "Jan 1, 00:00 or 12:00 PM"
                    hour: `%b %e, ${getHighchartTimeFormat()}`,   // "Jan 1, 00:00 or 12:00 PM"
                    day: '%a, %b %e',       // "Mon, Jan 1"
                    week: '%a, %b %e',      // "Mon, Jan 1"
                    month: '%b %Y',         // "Jan 2023"
                    year: '%b %Y'           // "Jan 2023"
                }
                return window.Highcharts.dateFormat(format[this.axis.tickPositions.info.unitName], this.value)
            }
        }
    },
    scrollbar: {
        enabled: false,
    },
    tooltip: {
        outside: false
    }
};
