import { States } from '../../app.router';

export const DOCUMENT_TITLE_TRANSLATION_PREFIX = 'document_title_';
export const TRACKING_TITLE_TRANSLATION_PREFIX = 'tracking_title_';

export default {
    sensor_list_wasnt_loaded: 'There was an error while loading the list',

    sensor_transfer_was_completed: '%transferredCount% device(s) transferred successfully',
    sensor_transfer_was_partially_completed: '%failedCount% of %totalCount% device(s) could not be transferred.',
    sensor_transfer_wasnt_completed: 'Devices could not be transferred.<br>Please refresh the page and try again.',

    sensor_mode_UNKNOWN_MODE: 'Unknown mode',
    sensor_mode_LOW_POWER_STANDARD_MODE: 'Standard power usage',
    sensor_mode_HIGH_POWER_BOOST_MODE: 'Boost (high power usage)',

    sensor_batch_was_updated: 'Labels successfully updated',
    sensor_batch_was_partially_updated: 'Labels were partially updated. <br>Some devices could not be updated.',
    sensor_batch_wasnt_updated: 'Labels could not be updated. <br>Please refresh the page and try again.',
    sensor_batch_max_limit: 'Maximum of 100 devices can be selected together.',

    sensor_not_found: 'The Sensor could not be loaded.',
    
    sensor_ids_copied: 'Sensor IDs copied to clipboard.',
    sensor_ids_not_copied: 'Unable to copy Sensor IDs to clipboard.',

    ccon_seen_sensors_cant_be_loaded: 'Projects with over 2000 seen sensors can not be loaded. The "Copy Sensor IDs" button can be used instead.',

    device_events_wasnt_loaded: 'Events could not be loaded. <br>Please refresh the page and try again.',
    device_configuration_wasnt_loaded: 'Device configuration could not be loaded. <br>Please refresh the page and try again.',
    device_configuration_wasnt_updated: 'Device configuration could not be updated. <br>Please refresh the page and try again.',
    probe_coefficients_was_updated: 'Probe coefficients successfully updated.',
    project_desk_occupancy_configuration_was_updated: 'Occupancy detection mode successfully updated for this project.',
    project_desk_occupancy_configuration_wasnt_updated: 'Project configuration could not be updated. <br>Please refresh the page and try again.',

    projects_wasnt_fetched: 'Projects could not be fetched. <br>Please refresh the page and try again.',
    project_was_created: 'Project successfully created',
    project_wasnt_created: 'The project could not be created. <br>Please refresh the page and try again.',
    project_was_updated: 'Project successfully updated',
    project_wasnt_updated: 'The project could not be updated. <br>Please refresh the page and try again.',
    project_was_removed: 'Project successfully removed.',
    project_wasnt_removed: 'The Project could not be removed. <br>Please refresh the page and try again.',
    project_search_error: 'An error occured when searching for a Project.',
    project_not_found: 'The Project could not be loaded.',
    project_timezone_was_updated: 'Project timezone and location updated',
    project_timezone_wasnt_updated: 'Project timezone and location could not be updated. <br>Please refresh the page and try again.',
    project_no_valid_timezone_found: 'Could not find a valid timezone for the location',
    

    organization_list_error: 'There was an error while loading the list of organizations',

    dataconnectors_wasnt_fetched: 'Data Connectors could not be fetched. <br>Please refresh the page and try again.',
    dataconnector_was_created: 'Data Connector successfully created',
    dataconnector_wasnt_created: 'The Data Connector could not be created. <br>Please refresh the page and try again.',
    dataconnector_was_updated: 'Data Connector successfully updated',
    dataconnector_wasnt_updated: 'The Data Connector could not be updated <br>Please refresh the page and try again.',
    dataconnector_was_removed: 'Data Connector successfully removed.',
    dataconnector_wasnt_removed: 'The Data Connector could not be removed. <br>Please refresh the page and try again.',
    dataconnector_metrics_was_refreshed: 'Metrics successfully updated.',
    dataconnector_metrics_wasnt_refreshed: 'Metrics could not be updated. <br>Please refresh the page and try again.',
    dataconnector_not_found: 'The Data Connector could not be loaded.',
    dataconnector_sync_failed: 'Unable to synchronize Data Connector.',
    dataconnector_sync_already_in_progress: 'Data Connector synchronization already in progress.',
    dataconnector_sync_started: 'Data Connector synchronization started.',

    members_wasnt_fetched: 'Members could not be fetched. <br>Please refresh the page and try again.',
    member_was_created: 'Member successfully invited',
    member_wasnt_created: 'Member could not be invited. <br>Please refresh the page and try again.',
    member_was_updated: 'Member successfully updated',
    member_wasnt_updated: 'Member could not be updated <br>Please refresh the page and try again.',
    member_was_removed: 'Member successfully removed.',
    member_wasnt_removed: 'Member could not be removed. <br>Please refresh the page and try again.',
    member_invite_was_sent: 'Invitation email sent.',
    member_invite_wasnt_sent: 'Invitation email could not be sent. <br>Please refresh the page and try again.',
    member_not_found: 'Member details could not be loaded.',

    member_access_updated: 'Member access updated',
    member_access_wasnt_updated: 'Access could not be updated <br>Please refresh the page and try again.',

    service_accounts_wasnt_fetched: 'Service Accounts could not be fetched. <br>Please refresh the page and try again.',
    service_account_was_created: 'Service Account successfully created',
    service_account_wasnt_created: 'Service Account could not be created. <br>Please refresh the page and try again.',
    service_account_was_updated: 'Service Account successfully updated',
    service_account_wasnt_updated: 'Service Account could not be updated <br>Please refresh the page and try again.',
    service_account_was_removed: 'Service Account successfully removed.',
    service_account_wasnt_removed: 'Service Account could not be removed. <br>Please refresh the page and try again.',

    service_account_role_was_updated: 'Role successfully updated',
    service_account_role_wasnt_updated: 'Role could not be updated <br>Please refresh the page and try again.',
    service_account_role_wasnt_updated_org_access: 'Role could not be updated since the Service Account is an Organization Administrator.',

    service_account_keys_wasnt_fetched: 'Keys could not be fetched. <br>Please refresh the page and try again.',
    service_account_key_was_created: 'Key successfully created',
    service_account_key_wasnt_created: 'Key could not be created. <br>Please refresh the page and try again.',
    service_account_key_was_removed: 'Key successfully removed.',
    service_account_key_wasnt_removed: 'Key could not be removed. <br>Please refresh the page and try again.',
    service_account_not_found: 'Service account could not be loaded.',

    label_was_created: 'Label successfully created',
    label_wasnt_created: 'The label could not be created. <br>Please refresh the page and try again.',
    label_was_updated: 'Label successfully updated',
    label_wasnt_updated: 'The label could not be updated <br>Please refresh the page and try again.',
    label_name_was_updated: 'Name successfully updated',
    label_name_wasnt_updated: 'The name could not be updated <br>Please refresh the page and try again.',
    label_desc_was_updated: 'Description successfully updated',
    label_desc_wasnt_updated: 'The description could not be updated <br>Please refresh the page and try again.',
    label_was_removed: 'Label successfully removed.',
    label_wasnt_removed: 'The label could not be removed. <br>Please refresh the page and try again.',

    ticket_was_submitted: 'The support ticket was submitted, and a receipt will be sent to the email '
        + 'address you entered.',
    ticket_wasnt_submitted: 'The support ticket could not be submitted. Please try again in a few minutes.',

    feedback_sent: 'We appreciate your feedback, thank you!',
    feedback_wasnt_sent: 'Oops! Something went wrong when trying to send. Please contact DT Support directly.',

    copy_to_clipboard_was_successful: '%label% is copied to clipboard',
    copy_to_clipboard_wasnt_successful: 'Unable to copy to clipboard',

    exports_wasnt_fetched: 'Reports could not be fetched. <br>Please refresh the page and try again.',
    export_was_created: 'Your report is being generated',
    export_wasnt_created: 'Report could not be generated. <br>Please refresh the page and try again.',

    temperature_unit_saved: 'Temperature unit preferences updated successfully',
    temperature_unit_not_saved: 'Unable to update the preferences',

    notification_was_created: 'Notification successfully created',
    notification_was_updated: 'Notification successfully updated',
    notification_was_deleted: 'The notification was deleted',

    notification_wasnt_deleted: 'The notification could not be deleted. <br>Please refresh the page and try again.',
    notification_wasnt_created: 'The notification could not be created. <br>Please refresh the page and try again.',
    notification_wasnt_updated: 'The notification could not be updated. <br>Please refresh the page and try again.',

    alert_was_muted: 'Alert successfully muted',
    alert_wasnt_muted: 'The alert could not be muted. <br>Please refresh the page and try again.',
    alert_was_unmuted: 'Alert successfully unmuted',
    alert_wasnt_unmuted: 'The alert could not be unmuted. <br>Please refresh the page and try again.',

    alerts_was_enabled: 'Alerts successfully enabled',
    alerts_wasnt_enabled: 'Alerts could not be enabled. <br>Please refresh the page and try again.',
    alerts_was_disabled: 'Alerts successfully disabled',
    alerts_wasnt_unmuted: 'Alerts could not be disabled. <br>Please refresh the page and try again.',


    emulated_device_was_created: 'Emulated Device successfully created',
    emulated_device_was_removed: 'Emulated Device successfully removed',

    emulated_device_wasnt_created: 'Unable to create emulated device',
    emulated_device_wasnt_removed: 'Unable to remove emulated device',

    emulated_devices_wasnt_loaded: 'Unable to fetch emulated devices',

    emulator_event_was_published: 'Event published',
    emulator_event_wasnt_published: 'Unable to publish event',

    dashboard_devices_was_added: 'Successfully added to Project Dashboard',
    dashboard_devices_wasnt_added: 'Could not add to Project Dashboard<br>Please refresh the page and try again.',
    dashboard_card_was_renamed: 'Card name updated',
    dashboard_too_many_cards: 'The maximum number of cards allowed is %limit%, this would bring that up to %count%',
    dashboard_was_deleted: 'Dashboard successfully deleted',
    dashboard_wasnt_deleted: 'Dashboard could not be deleted. <br>Please refresh the page and try again.',
    dashboard_name_wasnt_updated: 'Dashboard name could not be updated. <br>Please refresh the page and try again.',
    dashboard_minimum_two_devices: 'The card requires a minimum of two sensors.',
    dashboard_card_failed_to_load: 'One or more cards failed to load. <br>Please refresh the page and try again.',

    billing_info_was_updated: 'Billing information successfully updated',
    billing_info_wasnt_updated: 'Unable to update billing information. <br>Please refresh the page and try again.',

    subscription_device_renew_wasnt_updated: 'Unable to update device renew status. <br>Please refresh the page and try again.',

    no_camera: 'Unable to detect a camera for this device.',

    organization_wasnt_created: 'Unable to create organization. <br>Please refresh the page and try again.',

    claiming_already_claimed: 'This Kit/Device has already been claimed.',
    claiming_already_added_to_list: 'Already added to the list.',
    claiming_claiming_completed: '%deviceCount% device(s) added successfully',

    calibration_certificate_wasnt_downloaded: 'Unable to download certificate.<br>Please contact DT Support if the issue persists.',
    
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.AUTH)}`]:
        'DT Studio - Authenticating',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.AUTH_PROCESS)}`]:
        'DT Studio - Logging In',   
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.NEW_USER_FLOW)}`]:
        'DT Studio - Create Organization',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.PROJECTS)}`]:
        'DT Studio - Projects',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.DASHBOARD)}`]:
        'DT Studio - %projectName% Dashboard',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.DASHBOARD_SPECIFIED)}`]:
        'DT Studio - %projectName% Dashboard',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.PROJECT_DETAILS)}`]:
        'DT Studio - %projectName% (%organizationName%)',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.SENSORS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Sensors & Cloud Connectors',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.SENSOR_DETAILS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Sensors & Cloud Connectors - Details',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.DATA_CONNECTORS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Data Connectors',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.DATA_CONNECTOR_DETAILS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Data Connectors - Details',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.PROJECT_SETTINGS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Project Settings',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.PROJECT_SETTINGS_MEMBER)}`]:
        'DT Studio - %projectName% (%organizationName%) - Project Settings',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.SERVICE_ACCOUNTS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Service Accounts',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.SERVICE_ACCOUNT_DETAILS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Service Accounts - Details',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.RULES)}`]:
        'DT Studio - %projectName% (%organizationName%) - Notifications',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.RULE_DETAIL)}`]:
        'DT Studio - %projectName% (%organizationName%) - Notification details',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.ALERTS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Alerts',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.ALERTS_RULES)}`]:
        'DT Studio - %projectName% (%organizationName%) - Alerts',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.ALERTS_RULES_DETAIL)}`]:
        'DT Studio - %projectName% (%organizationName%) - Alerts',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.EXPORTS)}`]:
        'DT Studio - %projectName% (%organizationName%) - File Export',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.EXPORT)}`]:
        'DT Studio - %projectName% (%organizationName%) - File Export',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.EMULATOR)}`]:
        'DT Studio - %projectName% (%organizationName%) - Emulator',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.EMULATOR_DETAILS)}`]:
        'DT Studio - %projectName% (%organizationName%) - Emulator Details',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.ORGANIZATION_DETAILS)}`]:
        'DT Studio - %organizationName% Details',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.ORGANIZATION_SETTINGS)}`]:
        'DT Studio - %organizationName% Members',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.ORGANIZATION_SETTINGS_MEMBER)}`]:
        'DT Studio - %organizationName% Members',
    [`${DOCUMENT_TITLE_TRANSLATION_PREFIX}${(States.ORGANIZATION_BILLING)}`]:
        'DT Studio - %organizationName% Billing',

    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.AUTH)}`]:
        'DT Studio / Authenticating',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.AUTH_PROCESS)}`]:
        'DT Studio / Logging In',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.PROJECTS)}`]:
        'DT Studio / Projects',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.PROJECT_DETAILS)}`]:
        'DT Studio / Project Details',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.SENSORS)}`]:
        'DT Studio / Sensors & Cloud Connectors',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.SENSOR_DETAILS)}`]:
        'DT Studio / Sensor or Cloud Connector Details',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.DATA_CONNECTORS)}`]:
        'DT Studio / Data Connectors',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.DATA_CONNECTOR_DETAILS)}`]:
        'DT Studio / Data Connector Details',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.PROJECT_SETTINGS)}`]:
        'DT Studio / Project Settings',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.PROJECT_SETTINGS_MEMBER)}`]:
        'DT Studio / Project Settings',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.SERVICE_ACCOUNTS)}`]:
        'DT Studio / Service Accounts',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.SERVICE_ACCOUNT_DETAILS)}`]:
        'DT Studio / Service Account Details',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.EXPORTS)}`]:
        'DT Studio / File Export',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.ORGANIZATION_DETAILS)}`]:
        'DT Studio / Organization Details',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.ORGANIZATION_SETTINGS)}`]:
        'DT Studio / Organization Settings',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.ORGANIZATION_SETTINGS_MEMBER)}`]:
        'DT Studio / Organization Settings',
    [`${TRACKING_TITLE_TRANSLATION_PREFIX}${(States.ORGANIZATION_BILLING)}`]:
        'DT Studio / Organization Billing',
};
